import { Canvas, CanvasCreate } from "@tikifu/shared/types";
import { API_URL } from "../util/config";
import { del, post } from "../util/req";

const createCanvas = (body: CanvasCreate) => {
  return post<Canvas>(`${API_URL}/canvases`, body);
};

const deleteCanvas = (id: number) => {
  return del<Canvas>(`${API_URL}/canvases/${id}`);
};

export { createCanvas, deleteCanvas };
