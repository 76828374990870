import {
  createRef,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { TextProperties } from "@tikifu/shared/types";
import { useParams } from "react-router-dom";
import { Element } from "../../../elements/Element";
import { TextElement } from "../../../elements/TextElement";
import textPropertiesConfig from "../../../configs/textObjectConfig";
import { isImageElement, isTextElement } from "../../../util/guards";
import { CanvasRefState, Modal } from "../../../types";
import Button from "../../../components/Button";
import ColorPicker from "../../../components/ColorPicker";
import Dropdown from "../../../components/Dropdown";
import { useAppStore } from "../../../hooks/useAppStore";
import useDraw from "../../../hooks/useDraw";
import { debounce, urlsToJpeg } from "../../../util/helpers";
import {
  createElement,
  deleteElement,
  updateElement,
} from "../../../services/elements";
import { ImageElement } from "../../../elements/ImageElement";
import { createCanvas, deleteCanvas } from "../../../services/canvases";
import { createImages } from "../../../services/images";
import { Modals } from "./Modals";

interface OptionsProps {
  canvasRef: MutableRefObject<CanvasRefState[]>;
}

const Options = ({ canvasRef }: OptionsProps) => {
  const { setCanvasRender } = useAppStore();
  const { id: projectId } = useParams();
  const modalsRef = useRef<{ openModal: (modalType: Modal) => void }>();
  const folderFileInputRef = useRef<HTMLInputElement>(null);
  const singleFileInputRef = useRef<HTMLInputElement>(null);
  const textFileInputRef = useRef<HTMLInputElement>(null);

  const draw = useDraw();

  const debouncedUpdateObject = useCallback(
    debounce(
      (...args: Parameters<typeof updateElement>) => updateElement(...args),
      500,
    ),
    [],
  );

  const handlers = useMemo(
    () => ({
      handlePropertyChange: async <T extends keyof TextProperties>(
        property: T,
        value: TextProperties[T],
      ) => {
        for (const data of canvasRef.current) {
          for (const obj of data.elements) {
            if (
              obj.state.selected &&
              property in obj.properties &&
              isTextElement(obj)
            ) {
              obj.properties[property] = value;
              if (obj.dbId) {
                const updatedProperties: Partial<TextProperties> = {
                  [property]: value,
                };
                debouncedUpdateObject(obj.dbId, {
                  properties: updatedProperties,
                });
              }
              if (property === "fontFamily") {
                await obj.loadFont();
                draw(data);
              }
            }
          }
          draw(data);
        }
      },
      handleFileSelect: async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files ?? []);
        const imageUrls = files.map((file) => URL.createObjectURL(file));
        const jpegBlobs: Blob[] = await urlsToJpeg(imageUrls);

        const bgImg = new ImageElement(
          imageUrls,
          { x: 0, y: 0 },
          "auto",
          "auto",
        );

        const elements = [bgImg];

        const newCanvasState: CanvasRefState = {
          id: Symbol(canvasRef.current.length),
          ref: createRef<HTMLCanvasElement>(),
          elements,
          selected: false,
        };

        canvasRef.current = [...canvasRef.current, newCanvasState];
        await elements[0].load();
        setCanvasRender((prev) => prev + 1);
        event.target.value = "";

        if (!projectId) return;

        try {
          const { id } = await createCanvas({ projectId: Number(projectId) });
          const { urls } = await createImages({ images: jpegBlobs });
          newCanvasState.dbId = id;
          await createElement({
            canvasId: id,
            properties: { ...bgImg.properties, sources: urls },
          });
        } catch (e: unknown) {
          console.error(e);
        }
      },
      handleAddSingleText: async (texts: string[]) => {
        for (const data of canvasRef.current) {
          if (data.selected) {
            const newTextObj = new TextElement([...texts]);
            data.elements.push(newTextObj);
            await newTextObj.loadFont();
            draw(data);

            if (!data.dbId) return;

            try {
              const obj = await createElement({
                canvasId: data.dbId,
                properties: newTextObj.properties,
              });
              newTextObj.dbId = obj.id;
            } catch (e: unknown) {
              console.error(e);
            }
          }
        }
      },
      handleAddTextFromFile: (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
          return;
        }
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target?.result;
          if (text && typeof text === "string") {
            void handlers.handleAddSingleText(text.split(/\r?\n/));
          }
        };
        reader.readAsText(file);
      },
      handleRandomizeImage: () => {
        for (const data of canvasRef.current) {
          if (data.selected) {
            const bgImg = data.elements.find(isImageElement);
            if (bgImg) {
              bgImg.properties.currentSource =
                bgImg.properties.currentSource <
                bgImg.properties.sources.length - 1
                  ? bgImg.properties.currentSource + 1
                  : 0;
              void bgImg.load().then(() => {
                draw(data);
              });
            }
          }
        }
      },
      handleRandomizeText: () => {
        for (const data of canvasRef.current) {
          for (const obj of data.elements) {
            if (obj.state.selected && isTextElement(obj)) {
              obj.properties.fontSize = 0;
              obj.properties.text =
                obj.properties.texts[
                  Math.floor(Math.random() * obj.properties.texts.length)
                ];
            }
          }
          draw(data);
        }
      },
      handleMakeUnique: () => {
        for (const data of canvasRef.current) {
          if (data.selected) {
            const canvas = data.ref.current;
            if (!canvas) return;
            const ctx = canvas.getContext("2d");
            if (!ctx) return;
            const imageData = ctx.getImageData(
              0,
              0,
              canvas.width,
              canvas.height,
            );
            const randomX = Math.floor(Math.random() * canvas.width);
            const randomY = Math.floor(Math.random() * canvas.height);
            const index = (randomY * canvas.width + randomX) * 4;
            imageData.data[index] = 0;
            imageData.data[index + 1] = 0;
            imageData.data[index + 2] = 0;
            imageData.data[index + 3] = 255;
            ctx.putImageData(imageData, 0, 0);
          }
          draw(data);
        }
      },
      handleCanvasRemove: () => {
        const newCanvasState = [];
        for (const data of canvasRef.current) {
          if (!data.selected) {
            newCanvasState.push(data);
          } else {
            if (data.dbId) {
              void deleteCanvas(data.dbId);
            }
          }
        }
        canvasRef.current = newCanvasState;
        setCanvasRender((prev) => prev + 1);
      },
      handleElementRemove: () => {
        for (const state of canvasRef.current) {
          const newElements = [];
          for (const element of state.elements) {
            if (!element.state.selected) {
              newElements.push(element);
            } else {
              if (element.dbId) {
                void deleteElement(element.dbId);
              }
            }
            state.elements = newElements;
          }
          draw(state);
        }
      },
    }),
    [canvasRef],
  );

  const selectedElements = canvasRef.current
    .map((data) => data.elements)
    .flat()
    .filter((obj) => obj.state.selected);

  const showCanvasOptions = canvasRef.current.find((data) => data.selected);

  const showRandomizeImage = canvasRef.current.find((data) => {
    const bgImg = data.elements.find(isImageElement);
    if (!bgImg) return false;
    return bgImg.properties.sources.length > 1 && data.selected;
  });

  const showRandomizeText = selectedElements.some(
    (element) => isTextElement(element) && element.properties.texts.length > 1,
  );

  const getCommonValue = (
    property: string,
    typeguard: (element: Element) => element is TextElement,
  ) => {
    if (selectedElements.length === 0) return "";
    const typedSelectedElements = selectedElements.filter(typeguard);
    const uniqueProperties = new Set<string | string[] | number>();
    typedSelectedElements.forEach((element) =>
      uniqueProperties.add(
        element.properties[property as keyof Omit<TextProperties, "active">],
      ),
    );
    return uniqueProperties.size === 1 ? [...uniqueProperties][0] : "";
  };

  const handleAddSlide = (option: string) => {
    if (option === "Folder") {
      folderFileInputRef.current?.click();
    } else {
      singleFileInputRef.current?.click();
    }
  };

  const handleAddText = (option: string) => {
    if (option === "File") {
      textFileInputRef.current?.click();
    } else {
      void handlers.handleAddSingleText(["new text"]);
    }
  };

  const handleOpenModal = (modalType: Modal) => {
    if (!modalsRef.current) return;
    modalsRef.current.openModal(modalType);
  };

  return (
    <>
      <Modals ref={modalsRef} />
      <div
        id="top-bar"
        className="sticky top-0 left-0 w-full bg-[#1a1a1a] shadow-md gray-800 text-white flex flex-wrap items-center justify-center gap-4 py-3 min-h-[61px] box-border z-[1000]"
      >
        {showCanvasOptions && (
          <>
            <Dropdown
              options={["File", "Single"]}
              onSelect={handleAddText}
              defaultValue="Add Text"
            />
            <input
              className="hidden"
              ref={textFileInputRef}
              type="file"
              onChange={handlers.handleAddTextFromFile}
            />
            <Button onClick={handlers.handleMakeUnique}>Make unique</Button>
            {showRandomizeImage && (
              <Button onClick={handlers.handleRandomizeImage}>Randomize</Button>
            )}
            <Button onClick={handlers.handleCanvasRemove}>Remove</Button>
          </>
        )}

        {selectedElements.length === 0 && !showCanvasOptions && (
          <>
            <Dropdown
              options={["Folder", "Single"]}
              onSelect={handleAddSlide}
              defaultValue="Add Slide"
            />
            <input
              ref={folderFileInputRef}
              type="file"
              accept="image/*"
              className="hidden"
              multiple
              // @ts-expect-error Webkitdirectory is not recognized by TypeScript
              webkitdirectory="true"
              onChange={(e) => void handlers.handleFileSelect(e)}
            />
            <input
              ref={singleFileInputRef}
              type="file"
              accept="image/*"
              className="hidden"
              onChange={(e) => void handlers.handleFileSelect(e)}
            />
            <Button
              onClick={() => {
                handleOpenModal("upload");
              }}
            >
              Upload
            </Button>
            <Button
              onClick={() => {
                handleOpenModal("download");
              }}
            >
              Download
            </Button>
            <Button
              onClick={() => {
                handleOpenModal("schedule");
              }}
            >
              Schedule
            </Button>
          </>
        )}
        {selectedElements.length > 0 && (
          <>
            {Object.keys(textPropertiesConfig).map((property) => {
              const typedProperty =
                property as keyof typeof textPropertiesConfig;
              const config = textPropertiesConfig[typedProperty];
              const commonValue = getCommonValue(property, isTextElement);

              return (
                <div
                  key={typedProperty}
                  className="flex flex-row gap-2 items-center"
                >
                  <label>{config.label}</label>
                  {config.type === "select" && config.options ? (
                    <select
                      key={String(commonValue)}
                      defaultValue={commonValue}
                      className="bg-transparent text-white border border-white py-1 px-2 rounded cursor-pointer transition-all duration-300 ease-in-out text-base outline-none hover:bg-white hover:text-gray-900 hover:border-white focus:bg-white focus:text-gray-900 focus:border-white"
                      onChange={(e) => {
                        void handlers.handlePropertyChange(
                          typedProperty,
                          e.target.value,
                        );
                      }}
                    >
                      {config.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : config.type === "color" ? (
                    <ColorPicker
                      key={String(commonValue)}
                      defaultValue={
                        typeof commonValue === "string"
                          ? commonValue
                          : "#000000"
                      }
                      onChange={(color) => {
                        void handlers.handlePropertyChange(
                          typedProperty,
                          color,
                        );
                      }}
                    />
                  ) : (
                    <input
                      key={String(commonValue)}
                      type={config.type}
                      className="bg-transparent text-white border border-white py-1 px-2 rounded cursor-pointer transition-all duration-300 ease-in-out text-base outline-none hover:bg-white hover:text-gray-900 hover:border-white focus:bg-white focus:text-gray-900 focus:border-white"
                      min={config.min}
                      max={config.max}
                      step={config.step}
                      defaultValue={commonValue}
                      onChange={(e) => {
                        const value =
                          config.type === "number"
                            ? Number(e.target.value)
                            : e.target.value;
                        void handlers.handlePropertyChange(
                          typedProperty,
                          value,
                        );
                      }}
                    />
                  )}
                </div>
              );
            })}
            {showRandomizeText && (
              <Button onClick={handlers.handleRandomizeText}>Randomize</Button>
            )}
            <Button onClick={handlers.handleElementRemove}>Remove</Button>
          </>
        )}
      </div>
    </>
  );
};

export default Options;
