import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import JSZip from "jszip";
import saveAs from "file-saver";
import Button from "../../../components/Button";
import Timeline from "../../../components/Timeline";
import { scheduleTiktokPool } from "../../../services/schedule";
import { uploadTiktokSlideshow } from "../../../services/upload";
import { useAppStore } from "../../../hooks/useAppStore";
import { useCanvasContext } from "../CanvasContext";
import { isImageElement, isTextElement } from "../../../util/guards";
import Modal from "../../../components/Modal";
import { generateSlideshows } from "../helpers";
import useDraw from "../../../hooks/useDraw";
import { Modal as ModalType } from "../../../types";

const DownloadModalContent = () => {
  const { canvasRef } = useCanvasContext();
  const draw = useDraw();
  const [downloadAmount, setDownloadAmount] = useState(1);

  const maxCombinations = canvasRef.current.reduce((combinations, data) => {
    const bgImg = data.elements.find(isImageElement);
    if (!bgImg) return combinations;
    const variations =
      bgImg.properties.sources.length *
      data.elements.reduce((amount, obj) => {
        if (!isTextElement(obj)) return amount;
        return obj.properties.texts.length * amount;
      }, 1);
    return combinations * variations;
  }, 1);

  const handleDownloadSlideshows = useCallback(
    async (downloadAmount: number) => {
      const zip = new JSZip();
      let combinationIndex = 0;

      const slideshows = generateSlideshows(canvasRef, downloadAmount);

      for (const slideshow of slideshows) {
        const imagePromises: Promise<void>[] = [];

        slideshow.forEach((slide, index) => {
          canvasRef.current[index].elements
            .filter(isTextElement)
            .forEach((element, i) => {
              element.properties.text = slide.texts[i];
            });
          const bgImg = canvasRef.current[index].elements.find(isImageElement);
          if (bgImg) {
            const imageIndex = bgImg.properties.sources.findIndex(
              (source) => source === slide.image,
            );
            bgImg.properties.currentSource = imageIndex;
            imagePromises.push(
              bgImg.load().then(() => {
                draw(canvasRef.current[index]);
              }),
            );
          }
        });

        await Promise.all(imagePromises);

        const folder = zip.folder(`${combinationIndex}`);

        const capturePromises = canvasRef.current.map((data, i) => {
          return new Promise<void>((resolve) => {
            if (!folder || !data.ref.current) return;
            data.ref.current.toBlob((blob) => {
              if (!blob) return;
              folder.file(`${i}.jpeg`, blob);
              resolve();
            });
          });
        });
        await Promise.all(capturePromises);
        combinationIndex++;
      }

      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "slideshows.zip");
    },
    [],
  );

  return (
    <>
      <label htmlFor="slider">Slideshows amount: {downloadAmount}</label>
      <input
        type="range"
        id="slider"
        min="1"
        max={maxCombinations}
        step="1"
        value={downloadAmount}
        onChange={(e) => {
          setDownloadAmount(Number(e.target.value));
        }}
      />
      <Button
        color="black"
        onClick={() => void handleDownloadSlideshows(downloadAmount)}
      >
        Download
      </Button>
    </>
  );
};

const UploadModalContent = () => {
  const { selectedUser } = useAppStore();
  const { id: projectId } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleTiktokUpload = async () => {
    if (!selectedUser || !projectId) return;

    try {
      await uploadTiktokSlideshow(selectedUser, {
        projectId: Number(projectId),
        title,
        description,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <h2>Title</h2>
      <input
        type="text"
        className="bg-transparent text-black border border-black py-1 px-2 rounded cursor-pointer transition-all duration-300 ease-in-out text-base"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
      />
      <h2>Description</h2>
      <textarea
        className="bg-transparent text-black border border-black py-1 px-2 rounded cursor-pointer transition-all duration-300 ease-in-out text-base"
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
        rows={5}
      />
      <Button
        access="pro"
        provider="tiktok"
        color="black"
        onClick={() => void handleTiktokUpload()}
      >
        Upload as draft
      </Button>
    </>
  );
};

const ScheduleModalContent = () => {
  const { selectedUser } = useAppStore();
  const { id: projectId } = useParams();
  const poolStartHours = useRef<Set<number>>(new Set());

  const handleScheduleSlideshows = () => {
    const startHours = Array.from(poolStartHours.current);

    if (!startHours.length || !projectId || !selectedUser) return;

    void scheduleTiktokPool(selectedUser, {
      projectId: Number(projectId),
      startHours,
      combinationIndex: 0,
    });
  };

  return (
    <>
      <Timeline
        onToggle={(startHours) => {
          poolStartHours.current = startHours;
        }}
      />
      <Button access="pro" color="black" onClick={handleScheduleSlideshows}>
        Schedule
      </Button>
    </>
  );
};

const MODAL_TYPES = ["download", "schedule", "upload"];

export const Modals = forwardRef((_, ref) => {
  const { modal, setModal } = useAppStore();

  useImperativeHandle(ref, () => ({
    openModal: (type: ModalType) => {
      setModal(type);
    },
  }));

  return (
    <Modal
      open={modal ? MODAL_TYPES.includes(modal) : false}
      onClose={() => {
        setModal(null);
      }}
    >
      {modal === "download" ? (
        <DownloadModalContent />
      ) : modal === "schedule" ? (
        <ScheduleModalContent />
      ) : modal === "upload" ? (
        <UploadModalContent />
      ) : null}
    </Modal>
  );
});

Modals.displayName = "Modals";
