import {
  ProjectCreate,
  ProjectBase,
  Project,
  ProjectPage,
  ProjectUpdate,
} from "@tikifu/shared/types";
import { API_URL } from "../util/config";
import { del, get, post, put } from "../util/req";

const createProject = (body: ProjectCreate) =>
  post<ProjectBase>(`${API_URL}/projects`, body);

const getProjects = (query: { limit: number; offset: number }) =>
  get<ProjectPage>(
    `${API_URL}/projects?limit=${query.limit}&offset=${query.offset}`,
  );

const getProject = (id: number) => get<Project>(`${API_URL}/projects/${id}`);

const deleteProject = (id: number) => del<object>(`${API_URL}/projects/${id}`);

const updateProject = (id: number, body: ProjectUpdate) =>
  put<ProjectBase>(`${API_URL}/projects/${id}`, body);

export { createProject, getProjects, getProject, deleteProject, updateProject };
