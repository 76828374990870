import { Images, ImagesCreate } from "@tikifu/shared/types";
import { API_URL } from "../util/config";
import { post } from "../util/req";
import { createFormData } from "../util/helpers";

const createImages = (body: ImagesCreate) => {
  return post<Images>(`${API_URL}/images`, createFormData(body));
};

export { createImages };
