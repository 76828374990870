import { ImageProperties, TextProperties } from "@tikifu/shared/types";

export const createFormData = (
  data: Record<
    string,
    | string
    | Blob
    | File
    | number
    | boolean
    | string[]
    | Blob[]
    | TextProperties
    | ImageProperties
  >,
): FormData => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    } else if (typeof value === "boolean" || typeof value === "number") {
      formData.append(key, value.toString());
    } else if (typeof value === "object") {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export const debounce = <T extends (...args: Parameters<T>) => void>(
  func: T,
  delay: number,
): ((...args: Parameters<T>) => void) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const cartesianProduct = <T>(...arrays: T[][]): T[][] => {
  return arrays.reduce<T[][]>(
    (acc, array) => {
      const result: T[][] = [];
      acc.forEach((accItem) => {
        array.forEach((arrayItem) => {
          result.push([...accItem, arrayItem]);
        });
      });
      return result;
    },
    [[]],
  );
};

export const deterministicShuffle = <T>(array: T[], seed: string): T[] => {
  function hash(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0;
    }
    return hash;
  }

  const seedHash = hash(seed);

  return array.slice().sort((a, b) => {
    const hashA = hash(JSON.stringify(a) + String(seedHash));
    const hashB = hash(JSON.stringify(b) + String(seedHash));
    return hashA - hashB;
  });
};

export const urlsToJpeg = (dataUrls: string[]) => {
  return Promise.all(
    dataUrls.map(async (url) => {
      const img = new Image();
      img.src = url;

      await new Promise((resolve, reject) => {
        img.onload = resolve;

        img.onerror = (e) => {
          console.error("Error loading image:", e);
          reject(new Error("Image failed to load"));
        };
      });

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        throw new Error("Unable to get canvas context");
      }

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      return new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Canvas toBlob conversion failed"));
          }
        }, "image/jpeg");
      });
    }),
  );
};
