import {
  Route,
  Routes,
  Navigate,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import Edit from "./views/Edit";
import Login from "./views/Login";
import { googleLogin, verifyGoogleLogin, tiktokLogin } from "./services/auth";
import Projects from "./views/Projects";
import { useAppStore } from "./hooks/useAppStore";
import Loading from "./views/Loading";
import PlainEdit from "./views/Edit/PlainEdit";
import { getUser } from "./services/users";
import Sidebar from "./features/Sidebar";
import Modals from "./features/Modals";

{
  /*const loadGapiClient = () => {
  return new Promise<void>((resolve, reject) => {
    const initGapiClient = async () => {
      try {
        await gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
          ],
        });
        resolve();
      } catch (e) {
        console.error(e);
        reject(new Error("Loading gapi failed"));
      }
    };

    gapi.load("client", () => void initGapiClient());
  });
};

gapi.client.setToken({
  access_token: user.accessToken,
});
*/
}

const App = () => {
  const { loading, setLoading, user, setUser, setSelectedUser } = useAppStore();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const code = searchParams.get("code");
  const scope = searchParams.get("scope");
  const plainEdit = location.pathname === "/ao3248dfmwew0qdk90mgf0";

  useEffect(() => {
    const initialize = async () => {
      if (plainEdit) return;
      try {
        setLoading(true);
        if (code) {
          if (scope) {
            await googleLogin({ code });
          } else {
            await tiktokLogin({ code });
          }
        }

        const { userId } = await verifyGoogleLogin();

        const user = await getUser(userId);
        setUser(user);
        if (user.children.length) {
          setSelectedUser(user.children[0].id);
        }

        if (searchParams.size) {
          navigate("/projects ", { replace: true });
        }
      } catch (error: unknown) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    void initialize();
  }, [code, scope]);

  if (loading && !plainEdit) return <Loading />;

  return user ? (
    <div className="flex flex-row h-full w-full bg-[#222222]">
      <Modals />
      <Sidebar />
      <div className="grow overflow-hidden">
        <Routes>
          <Route path="/projects/:id" element={<Edit />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="*" element={<Navigate to="/projects" />} />
        </Routes>
      </div>
    </div>
  ) : (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path="/ao3248dfmwew0qdk90mgf0" element={<PlainEdit />} />
    </Routes>
  );
};

export default App;
