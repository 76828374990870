import { TiktokSlideshow } from "@tikifu/shared/types.js";
import { post } from "../util/req";
import { API_URL } from "../util/config";
import { TiktokSlideshowUpload } from "../types";

{
  /*const createDriveFile = async (file: Blob, folderId?: string) => {
  const boundary = "-------314159265358979323846";
  const delimiter = `--${boundary}\r\n`;
  const closeDelimiter = `\r\n--${boundary}--`;

  const fileMetadata = {
    name: "myfile",
    mimeType: file.type || "image/jpeg",
    parents: folderId ? [folderId] : undefined,
  };

  const base64FileData = await fileToBase64(file);

  const multipartRequestBody =
    delimiter +
    "Content-Type: application/json\r\n\r\n" +
    JSON.stringify(fileMetadata) +
    "\r\n" +
    delimiter +
    "Content-Type: " +
    file.type +
    "\r\n" +
    "Content-Transfer-Encoding: base64\r\n\r\n" +
    base64FileData +
    closeDelimiter;

  const res = await gapi.client.request({
    path: "https://www.googleapis.com/upload/drive/v3/files",
    method: "POST",
    params: { uploadType: "multipart" },
    headers: {
      "Content-Type": `multipart/related; boundary=${boundary}`,
    },
    body: multipartRequestBody,
  });

  return res.result as Promise<DriveFile>;
};

const makeDriveFilePublic = async (fileId: string) => {
  const res = await gapi.client.request({
    path: `/drive/v3/files/${fileId}/permissions`,
    method: "POST",
    body: {
      role: "reader",
      type: "anyone",
    },
  });

  return res.result as Promise<DrivePermission>;
};

const createDriveFolder = async (folderName: string) => {
  const res = await gapi.client.drive.files.create({
    resource: {
      name: folderName,
      mimeType: "application/vnd.google-apps.folder",
    },
    fields: "id",
  });
  return res.result as Promise<DriveFolder>;
};*/
}

const uploadTiktokSlideshow = (id: number, body: TiktokSlideshowUpload) => {
  return post<TiktokSlideshow>(`${API_URL}/upload/tiktok/slideshow`, body, {
    "x-tiktok-user-id": id.toString(),
  });
};

export { uploadTiktokSlideshow };
