import { useEffect, useState } from "react";
import { DotsThreeVertical, File, Pencil, Trash } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { ProjectPage } from "@tikifu/shared/types";
import {
  deleteProject,
  getProjects,
  updateProject,
} from "../services/projects";

const Projects = () => {
  const [projectPage, setProjectPage] = useState<ProjectPage>({
    data: [],
    total: 0,
  });
  const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 });
  const [selectedProject, setSelectedProject] = useState<number | null>(null);
  const [editingProject, setEditingProject] = useState<number | null>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      const projectPage = await getProjects({
        limit: 20,
        offset: 0,
      });
      setProjectPage({
        ...projectPage,
      });
    };

    void initialize();
  }, []);

  const handleViewMore = async () => {
    if (!isLoadingMore) {
      setIsLoadingMore(true);
      try {
        const newProjectPage = await getProjects({
          limit: 20,
          offset: projectPage.data.length,
        });

        setProjectPage((prev) => {
          const data = [...prev.data, ...newProjectPage.data];

          return {
            ...newProjectPage,
            data,
          };
        });
      } catch (error) {
        console.error("Error fetching more projects:", error);
      } finally {
        setIsLoadingMore(false);
      }
    }
  };

  const handleDelete = () => {
    if (!selectedProject) return;

    void deleteProject(selectedProject);
    setProjectPage((prev) => {
      const updatedData = prev.data.filter(
        (project) => project.id !== selectedProject,
      );

      return {
        ...prev,
        data: updatedData,
        total: prev.total - 1,
      };
    });

    setSelectedProject(null);
  };

  const handleEdit = () => {
    if (!selectedProject) return;

    setEditingProject(selectedProject);
    setSelectedProject(null);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!editingProject) return;
    void updateProject(editingProject, { name: e.target.value });
    setProjectPage({
      ...projectPage,
      data: projectPage.data.map((project) =>
        project.id === editingProject
          ? { ...project, name: e.target.value }
          : project,
      ),
    });
    setEditingProject(null);
  };

  const handleDotsClick = (event: React.MouseEvent, projectId: number) => {
    event.stopPropagation();
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();

    setDialogPosition({
      top: rect.bottom + window.scrollY - 5,
      left: rect.left + window.scrollX + 5,
    });
    setSelectedProject(projectId);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest(".dots")) {
      setSelectedProject(null);
    }
  };

  useEffect(() => {
    if (selectedProject) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [selectedProject]);

  return (
    <>
      <div
        className={`p-6 bg-[#111111] min-h-screen text-white ${projectPage.data.length === 0 ? "flex items-center justify-center" : ""}`}
      >
        {projectPage.data.length > 0 ? (
          <div className="flex flex-col">
            <span className="mb-3 ml-2">Name</span>
            {projectPage.data.map((project, index) => (
              <div
                key={index}
                role="button"
                tabIndex={0}
                className={`flex items-center grow p-4 justify-between border-[0.5px] border-[#333333] border-r-0 border-l-0 rounded-none py-1 ${index !== projectPage.data.length - 1 ? "border-b-0" : ""} ${project.id === selectedProject ? "bg-indigo-700" : "hover:bg-[#222222]"}`}
                onClick={() => {
                  navigate(`${project.id}`);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Space") {
                    e.preventDefault();
                    navigate(`${project.id}`);
                  }
                }}
              >
                <div className="flex items-center">
                  <File className="mr-3" size={24} />
                  {editingProject === project.id ? (
                    <input
                      autoFocus
                      type="text"
                      defaultValue={project.name}
                      className="bg-transparent focus:outline-none leading-8"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onBlur={handleBlur}
                    />
                  ) : (
                    <span className="truncate py-1">{project.name}</span>
                  )}
                </div>
                <button
                  onClick={(e) => {
                    handleDotsClick(e, project.id);
                  }}
                  className={`dots p-1 rounded-full hover:bg-[#3a3a3a] ${project.id === selectedProject ? "bg-indigo-700 hover:bg-[#554adc]" : ""}`}
                >
                  <DotsThreeVertical size={24} />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-lg text-center shrink">
            No projects available. Click &quot;New Project&quot; to create a new
            one.
          </div>
        )}

        {projectPage.total > projectPage.data.length && (
          <button
            className="mt-3 px-2 py-1 hover:bg-[#222222] rounded-xl"
            onClick={() => void handleViewMore()}
            disabled={isLoadingMore}
          >
            {isLoadingMore ? "Loading..." : "View more"}
          </button>
        )}

        {selectedProject && (
          <div
            style={{
              top: dialogPosition.top,
              left: dialogPosition.left,
            }}
            className="absolute shadow-lg rounded py-2 -translate-x-full dots whitespace-nowrap bg-[#222222]"
          >
            <button
              className="flex flex-row w-full items-center gap-2 hover:bg-[#3a3a3a] py-1 px-3"
              onClick={handleDelete}
            >
              <Trash size={20} />
              <span>Delete project</span>
            </button>
            <button
              className="flex flex-row w-full items-center gap-2 hover:bg-[#3a3a3a] py-1 px-3"
              onClick={handleEdit}
            >
              <Pencil size={20} />
              <span>Edit name</span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Projects;
