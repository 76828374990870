import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { CanvasRefState } from "../../types";
import useDraw from "../../hooks/useDraw";
import { getProject } from "../../services/projects";
import { isImageElement } from "../../util/guards";
import { useAppStore } from "../../hooks/useAppStore";
import Canvas from "./Canvas";
import Options from "./Options";
import { loadProject } from "./helpers";
import { CanvasProvider } from "./CanvasContext";

const Edit = () => {
  // @ts-expect-error canvasRender is used only for re-rendering
  // eslint-disable-next-line
  const { canvasRender, setCanvasRender } = useAppStore();
  const canvasRef = useRef<CanvasRefState[]>([]);

  const [lastClickedElement, setLastClickedElement] = useState<symbol | null>(
    null,
  );
  const [lastActiveElement, setLastActiveElement] =
    useState<HTMLElement | null>(null);

  const [draggedCanvas, setDraggedCanvas] = useState<symbol | null>(null);
  const [dragOffset, setDragOffset] = useState<number>(0);
  const [positions, setPositions] = useState<number[]>([]);
  const [transforms, setTransforms] = useState<Record<symbol, number>>({});
  const [transitions, setTransitions] = useState<Record<symbol, boolean>>({});
  const { id } = useParams();
  const draw = useDraw();

  useEffect(() => {
    const initialize = async () => {
      if (!id) return;
      const project = await getProject(Number(id));
      await loadProject(canvasRef, project);
      setCanvasRender((prev) => prev + 1);
    };

    void initialize();
  }, [id]);

  const handleMouseDown = (event: React.MouseEvent, data: CanvasRefState) => {
    for (const state of canvasRef.current) {
      const canvas = state.ref.current;
      if (!canvas) return;
      const rect = canvas.getBoundingClientRect();
      const threshold = canvas.width * 0.03;
      const bgImg = state.elements.find(isImageElement);

      if (!bgImg) return;

      const scale = bgImg.state.img.naturalWidth / rect.width;
      const clickX = (event.clientX - rect.left) * scale;
      const clickY = (event.clientY - rect.top) * scale;
      if (
        state.elements.find(
          (element) =>
            element.containsPoint(clickX, clickY, threshold) &&
            element.properties.active,
        )
      ) {
        return;
      }
    }

    const draggedElement = data.ref.current;

    if (!draggedElement) return;

    setDraggedCanvas(data.id);
    setDragOffset(event.clientX - draggedElement.getBoundingClientRect().left); // Set initial mouse offset
    setPositions(
      canvasRef.current.map(
        (data) => data.ref.current?.getBoundingClientRect().left ?? 0,
      ),
    );
  };

  const handleMouseUp = () => {
    setDraggedCanvas(null);
    setDragOffset(0);
    setTransforms({});
    setTransitions({});
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (draggedCanvas === null) return;

    const draggedCanvasIndex = canvasRef.current.findIndex(
      (data) => data.id === draggedCanvas,
    );
    const deltaX = event.clientX - dragOffset - positions[draggedCanvasIndex];
    const draggedElement =
      canvasRef.current[draggedCanvasIndex].ref.current?.parentElement;
    if (!draggedElement) return;

    setTransforms((prev) => ({
      ...prev,
      [draggedCanvas]: deltaX,
    }));

    const draggedRect = draggedElement.getBoundingClientRect();

    canvasRef.current.forEach((data, i) => {
      if (data.id !== draggedCanvas) {
        const otherCanvas = data.ref.current;
        if (!otherCanvas) return;

        const otherRect = otherCanvas.getBoundingClientRect();

        if (
          ((draggedRect.right > otherRect.left + otherRect.width / 2 + 10 &&
            draggedCanvasIndex < i) ||
            (draggedRect.left < otherRect.right - otherRect.width / 2 - 10 &&
              draggedCanvasIndex > i)) &&
          otherRect.left === positions[i]
        ) {
          canvasRef.current = [...canvasRef.current];
          const temp = canvasRef.current[draggedCanvasIndex];
          canvasRef.current[draggedCanvasIndex] = canvasRef.current[i];
          canvasRef.current[i] = temp;
          setTransforms((prevTransforms) => ({
            ...prevTransforms,
            [draggedCanvas]: event.clientX - dragOffset - positions[i],
            [data.id]: positions[i] - positions[draggedCanvasIndex],
          }));
          setTransitions((prev) => ({
            ...prev,
            [data.id]: false,
          }));
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              setTransitions((prev) => ({
                ...prev,
                [data.id]: true,
              }));
              setTransforms((prevTransforms) => ({
                ...prevTransforms,
                [data.id]: 0,
              }));
            });
          });
          return;
        }
      }
    });
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const clickedItem = event.target;
    if (!(clickedItem instanceof HTMLElement)) {
      return;
    }

    const topBar = document.getElementById("top-bar");

    const isInputOrSelect =
      event.target instanceof HTMLInputElement ||
      event.target instanceof HTMLSelectElement ||
      event.target instanceof HTMLTextAreaElement ||
      lastActiveElement instanceof HTMLTextAreaElement ||
      lastActiveElement instanceof HTMLInputElement ||
      lastActiveElement instanceof HTMLSelectElement;

    if (isInputOrSelect) {
      setLastActiveElement(clickedItem);
      return;
    }

    if (topBar?.contains(clickedItem)) {
      return;
    }

    const isClickOutside = canvasRef.current.every(
      (data) => data.ref.current && !data.ref.current.contains(clickedItem),
    );

    if (isClickOutside) {
      setLastClickedElement(null);
      for (const data of canvasRef.current) {
        data.selected = false;
        for (const element of data.elements) {
          element.state.selected = false;
        }
        draw(data);
      }
      setLastActiveElement(null);
    }
  };

  return (
    <CanvasProvider canvasRef={canvasRef}>
      <div
        className="flex flex-col grow h-full"
        role="presentation"
        onMouseDown={handleOutsideClick}
      >
        <Options canvasRef={canvasRef} />
        <div
          id="canvases"
          role="presentation"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          className="flex flex-row justify-center items-center grow gap-8 flex-wrap p-8 bg-[#111111]"
        >
          {canvasRef.current.map((data, index) => (
            <Canvas
              key={data.id.toString()}
              onMouseDown={(event) => {
                handleMouseDown(event, data);
              }}
              canvasRef={canvasRef}
              lastClickedElement={lastClickedElement}
              setLastClickedElement={setLastClickedElement}
              index={index}
              ref={canvasRef.current[index].ref}
              style={{
                transform: `translateX(${transforms[data.id] || 0}px)`,
                transition: transitions[data.id]
                  ? "transform 0.3s ease"
                  : "none",
                opacity: draggedCanvas === data.id ? 0.7 : 1,
                zIndex: draggedCanvas === data.id ? 1 : 0,
              }}
            />
          ))}
        </div>
      </div>
    </CanvasProvider>
  );
};

export default Edit;
