const API_URL = process.env.REACT_APP_API_URL ?? "http://localhost:5000";
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? "";
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY ?? "";
const TIKTOK_CLIENT_KEY = process.env.REACT_APP_TIKTOK_CLIENT_KEY ?? "";
const GOOGLE_REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI ?? "";
const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${GOOGLE_REDIRECT_URI}&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&access_type=offline&prompt=consent`;
const TIKTOK_AUTH_URL = `https://www.tiktok.com/v2/auth/authorize/?client_key=${TIKTOK_CLIENT_KEY}&scope=user.info.basic,video.upload&response_type=code&redirect_uri=https://app.tikifu.com&state=${Math.random().toString(36).substring(2)}&prompt=login`;
const ASSETS_URL = process.env.REACT_APP_ASSETS_URL ?? "";

export {
  API_URL,
  GOOGLE_CLIENT_ID,
  GOOGLE_API_KEY,
  TIKTOK_CLIENT_KEY,
  GOOGLE_AUTH_URL,
  TIKTOK_AUTH_URL,
  ASSETS_URL,
};
