import React, { useState } from "react";

interface ColorPickerProps {
  onChange: (color: string) => void;
  defaultValue: string;
}

const ColorPicker = ({ onChange, defaultValue }: ColorPickerProps) => {
  const [selectedColor, setSelectedColor] = useState(defaultValue);

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColor(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="relative inline-block">
      <input
        type="color"
        value={selectedColor}
        onChange={handleColorChange}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
      />
      <div
        className={`h-7 w-7 rounded-md border border-gray-300 shadow-sm cursor-pointer`}
        style={{ backgroundColor: selectedColor }}
      ></div>
    </div>
  );
};

export default ColorPicker;
