import React, { createContext, ReactNode, useContext } from "react";
import { CanvasRefState } from "../../types";

interface CanvasContextValue {
  canvasRef: React.MutableRefObject<CanvasRefState[]>;
}

export const CanvasContext = createContext<CanvasContextValue | undefined>(
  undefined,
);

interface CanvasProviderProps {
  children: ReactNode;
  canvasRef: React.MutableRefObject<CanvasRefState[]>;
}

export const CanvasProvider: React.FC<CanvasProviderProps> = ({
  children,
  canvasRef,
}) => {
  return (
    <CanvasContext.Provider value={{ canvasRef }}>
      {children}
    </CanvasContext.Provider>
  );
};

export const useCanvasContext = () => {
  const context = useContext(CanvasContext);

  if (!context) {
    throw new Error("useCanvasContext must be used within a CanvasProvider");
  }

  return context;
};
