import WebFont from "webfontloader";
import { TextProperties } from "@tikifu/shared/types.js";
import { TextState } from "../types";
import { Element } from "./Element";

const drawBackground = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  padding: number,
  borderRadius: number,
) => {
  const bgX = x - width / 2 - padding;
  const bgY = y - height / 2 - padding;
  const bgWidth = width + padding * 2;
  const bgHeight = height + padding * 2;

  ctx.beginPath();
  ctx.moveTo(bgX + borderRadius, bgY);
  ctx.lineTo(bgX + bgWidth - borderRadius, bgY);
  ctx.quadraticCurveTo(bgX + bgWidth, bgY, bgX + bgWidth, bgY + borderRadius);
  ctx.lineTo(bgX + bgWidth, bgY + bgHeight - borderRadius);
  ctx.quadraticCurveTo(
    bgX + bgWidth,
    bgY + bgHeight,
    bgX + bgWidth - borderRadius,
    bgY + bgHeight,
  );
  ctx.lineTo(bgX + borderRadius, bgY + bgHeight);
  ctx.quadraticCurveTo(bgX, bgY + bgHeight, bgX, bgY + bgHeight - borderRadius);
  ctx.lineTo(bgX, bgY + borderRadius);
  ctx.quadraticCurveTo(bgX, bgY, bgX + borderRadius, bgY);
  ctx.closePath();

  ctx.fill();
};

export class TextElement extends Element {
  properties: TextProperties;
  state: TextState;

  constructor(texts: string[]) {
    super();
    this.properties = {
      type: "text",
      active: true,
      texts: texts.map((text) => text.replace(/\\n/g, "\n")),
      text: texts[0] ?? "new text",
      x: 0,
      y: 0,
      fontSize: 0,
      fontFamily: "TikTok Sans",
      fillStyle: "white",
      fontWeight: "500",
      strokeStyle: "black",
      lineWidth: 1,
      position: "bottom",
      bg: "none",
      bgColor: "#ffff",
    };
    this.state = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      dragging: false,
      selected: false,
      editable: false,
    };
  }

  async loadFont(): Promise<void> {
    const fontFamily = this.properties.fontFamily;
    if (fontFamily === "TikTok Sans") {
      await document.fonts.load(`1em ${fontFamily}`);
    } else {
      return new Promise((resolve, reject) => {
        WebFont.load({
          google: {
            families: [`${fontFamily}:300,400,500,600,700`],
          },
          active: () => {
            resolve();
          },
          inactive: () => {
            reject(new Error("Google Font loading failed"));
          },
        });
      });
    }
  }

  draw(ctx: CanvasRenderingContext2D): void {
    const canvasWidth = ctx.canvas.width;
    const canvasHeight = ctx.canvas.height;

    if (!this.state.width || !this.state.height) {
      this.state.width = 0.5 * canvasWidth;
      this.state.height = 0.5 * canvasWidth;
    }

    if (this.properties.fontSize === 0) {
      this.properties.fontSize = 0.5 * canvasWidth;
    }

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.direction = "inherit";
    ctx.fillStyle = this.properties.fillStyle;
    ctx.lineWidth = this.properties.lineWidth;
    ctx.strokeStyle = this.properties.strokeStyle;

    const lines = this.properties.text.split("\n");

    let minFontSize = 1;
    let maxFontSize = Math.min(this.state.width, this.state.height); // Upper bound for font size
    let fontSize = minFontSize;

    while (minFontSize <= maxFontSize) {
      const midFontSize = Math.floor((minFontSize + maxFontSize) / 2);
      ctx.font = `${this.properties.fontWeight} ${midFontSize}px ${this.properties.fontFamily}`;

      const textMetrics = lines.map((line) => ctx.measureText(line));
      const textWidth = Math.max(
        ...textMetrics.map((metrics) => metrics.width),
      );
      const lineHeight = midFontSize * 1.2;
      const textHeight = lineHeight * lines.length;

      if (textWidth <= this.state.width && textHeight <= this.state.height) {
        fontSize = midFontSize;
        minFontSize = midFontSize + 1;
      } else {
        maxFontSize = midFontSize - 1;
      }
    }

    this.properties.fontSize = fontSize;

    if (this.properties.position === "top") {
      this.properties.y = canvasHeight * 0.14;
      this.properties.x = canvasWidth / 2;
      this.state.y = this.properties.y;
      this.state.x = this.properties.x;
    } else if (this.properties.position === "bottom") {
      this.properties.y = canvasHeight * 0.86;
      this.properties.x = canvasWidth / 2;
      this.state.y = this.properties.y;
      this.state.x = this.properties.x;
    }

    this.properties.position = "";
    const lineHeight = this.properties.fontSize * 1.2;
    const textMetrics = lines.map((line) => ctx.measureText(line));
    ctx.fillStyle = this.properties.bgColor;

    const maxTextHeight =
      lines.length * lineHeight -
      (lineHeight -
        (textMetrics[0].actualBoundingBoxAscent +
          textMetrics[0].actualBoundingBoxDescent)) /
        2 -
      (lineHeight -
        (textMetrics[textMetrics.length - 1].actualBoundingBoxAscent +
          textMetrics[textMetrics.length - 1].actualBoundingBoxDescent)) /
        2;

    const maxTextWidth = Math.max(
      ...textMetrics.map((metrics) => metrics.width),
    );

    if (this.properties.bg === "cover") {
      drawBackground(
        ctx,
        this.properties.x,
        this.properties.y,
        maxTextWidth,
        maxTextHeight,
        0.3 * this.properties.fontSize,
        0.02 * canvasWidth,
      );
    }

    if (this.properties.bg === "line") {
      lines.forEach((_, index) => {
        const y =
          this.properties.y -
          ((lines.length - 1) * lineHeight) / 2 +
          index * lineHeight;

        const textHeight =
          textMetrics[index].actualBoundingBoxAscent +
          textMetrics[index].actualBoundingBoxDescent;

        drawBackground(
          ctx,
          this.properties.x,
          y,
          textMetrics[index].width,
          textHeight,
          0.1 * this.properties.fontSize,
          0.02 * canvasWidth,
        );
      });
    }

    ctx.fillStyle = this.properties.fillStyle;

    lines.forEach((line, index) => {
      const offset =
        (textMetrics[index].actualBoundingBoxAscent -
          textMetrics[index].actualBoundingBoxDescent) /
        2;

      const y =
        this.properties.y -
        ((lines.length - 1) * lineHeight) / 2 +
        index * lineHeight +
        offset;

      ctx.fillText(line, this.properties.x, y);
      ctx.strokeText(line, this.properties.x, y);
    });
  }

  containsPoint(x: number, y: number, padding = 0): boolean {
    this.state.width += padding;
    this.state.height += padding;
    const r =
      x >= this.properties.x - this.state.width / 2 &&
      x <= this.properties.x + this.state.width / 2 &&
      y >= this.properties.y - this.state.height / 2 &&
      y <= this.properties.y + this.state.height / 2;
    this.state.width -= padding;
    this.state.height -= padding;
    return r;
  }
}
