import { ImageElement } from "../elements/ImageElement";
import { TextElement } from "../elements/TextElement";
import { Element } from "../elements/Element";

export const isTextElement = (element: Element): element is TextElement => {
  return element.properties.type === "text";
};

export const isImageElement = (element: Element): element is ImageElement => {
  return element.properties.type === "image";
};
