import { GoogleLogo } from "@phosphor-icons/react";
import Button from "../components/Button";
import { GOOGLE_AUTH_URL } from "../util/config";
import logoSrc from "../logo_white.svg";

const Login = () => {
  return (
    <div className="text-center flex flex-col items-center gap-6 justify-center h-full bg-[#111111]">
      <img className="w-40 h-auto" src={logoSrc} alt="Logo" />
      <p className="text-white">
        Sign in with your Google account to access Tikifu.
      </p>
      <Button
        color="white"
        onClick={() => {
          window.location.href = GOOGLE_AUTH_URL;
        }}
        className="flex items-center gap-2"
      >
        <GoogleLogo size={24} weight="bold" />
        Sign in with Google
      </Button>
    </div>
  );
};

export default Login;
