import { Project } from "@tikifu/shared/types";
import { createRef } from "react";
import { CanvasRefState } from "../../types";
import { ImageElement } from "../../elements/ImageElement";
import { TextElement } from "../../elements/TextElement";
import { isImageElement, isTextElement } from "../../util/guards";
import { cartesianProduct, deterministicShuffle } from "../../util/helpers";
import { Element } from "../../elements/Element";

export const loadProject = async (
  canvasRef: React.MutableRefObject<CanvasRefState[]>,
  project: Project,
  plain = false,
) => {
  const loadFontPromises: Promise<void>[] = [];
  const loadImagePromises: Promise<void>[] = [];

  for (const canvas of project.canvases) {
    let newRef;
    if (plain) {
      const canvas = document.createElement("canvas");
      newRef = { current: canvas };
    } else {
      newRef = createRef<HTMLCanvasElement>();
    }

    const elements: Element[] = [];

    canvas.elements.forEach((element) => {
      const properties = element.properties;
      if (properties.type === "image") {
        const bgImg = new ImageElement(
          properties.sources,
          { x: 0, y: 0 },
          "auto",
          "auto",
        );
        bgImg.state.img.crossOrigin = "anonymous";
        loadImagePromises.push(bgImg.load());
        elements.push(bgImg);
      }
      if (element.properties.type === "text") {
        const textObj = new TextElement(element.properties.texts);
        textObj.properties = element.properties;
        textObj.dbId = element.id;
        loadFontPromises.push(textObj.loadFont());
        elements.push(textObj);
      }
    });

    const newCanvasState: CanvasRefState = {
      id: Symbol(canvasRef.current.length),
      dbId: canvas.id,
      ref: newRef,
      elements,
      selected: false,
    };
    canvasRef.current.push(newCanvasState);
  }

  await Promise.all([...loadFontPromises, ...loadImagePromises]);
};

export const generateSlideshows = (
  canvasRef: React.MutableRefObject<CanvasRefState[]>,
  numSlideshows?: number,
) => {
  const allCombinations: { imageIndex: number; textIndexes: number[] }[][] = [];

  canvasRef.current.forEach((slide) => {
    const bgImg = slide.elements.find(isImageElement);

    if (!bgImg) {
      throw new Error("Background image missing from slide");
    }

    const images = bgImg.properties.sources;
    const textSets = slide.elements
      .filter(isTextElement)
      .map((obj) => obj.properties.texts);

    const textIndexSets = textSets.map((texts) =>
      texts.map((_, index) => index),
    );

    const textCombinations = cartesianProduct(...textIndexSets);

    const slideCombinations: { imageIndex: number; textIndexes: number[] }[] =
      [];
    images.forEach((_, imageIndex) => {
      textCombinations.forEach((textIndexes) => {
        slideCombinations.push({ imageIndex, textIndexes });
      });
    });

    allCombinations.push(slideCombinations);
  });

  const allSlideshows = cartesianProduct(...allCombinations);

  deterministicShuffle(allSlideshows, "deternimistic-seed");
  const limitedSlideshows = allSlideshows.slice(0, numSlideshows);

  const resolvedSlideshows = limitedSlideshows.map((slideshow) =>
    slideshow.map(({ imageIndex, textIndexes }, slideIndex) => {
      const slide = canvasRef.current[slideIndex];
      const bgImg = slide.elements.find(isImageElement);
      if (!bgImg) {
        throw new Error("Background image missing from slide");
      }
      const image = bgImg.properties.sources[imageIndex];
      const texts = textIndexes.map(
        (textIndex, objectIndex) =>
          slide.elements.filter(isTextElement)[objectIndex].properties.texts[
            textIndex
          ],
      );
      return { image, texts };
    }),
  );

  return resolvedSlideshows;
};
