import { useState, useEffect, useRef } from "react";
import { CaretDown } from "@phosphor-icons/react";
import { CaretUp } from "@phosphor-icons/react/dist/ssr";
import Button from "./Button";

interface DropdownProps {
  options: string[];
  onSelect: (option: string) => void;
  defaultValue: string;
  className?: string;
  direction?: "up" | "down";
}

const Dropdown = ({
  options,
  onSelect,
  defaultValue,
  className = "",
  direction = "down",
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: string) => {
    onSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <Button
        color="white"
        className="flex flex-row gap-2 items-center justify-between w-full"
        onClick={handleToggle}
      >
        {defaultValue}
        {direction === "down" ? (
          isOpen ? (
            <CaretUp size={16} />
          ) : (
            <CaretDown size={16} />
          )
        ) : isOpen ? (
          <CaretDown size={16} />
        ) : (
          <CaretUp size={16} />
        )}
      </Button>

      {isOpen && (
        <div
          className={`absolute left-0 w-full bg-gray-800 text-white border border-white rounded-md shadow-lg z-10 ${
            direction === "up" ? "bottom-full mb-1" : "top-full mt-1"
          }`}
        >
          {options.map((option, index) => (
            <button
              key={index}
              className="w-full px-3 py-2 text-left hover:bg-white hover:text-gray-900 transition-colors duration-200"
              onClick={() => {
                handleSelect(option);
              }}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
