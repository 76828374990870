import { ImageProperties, TextProperties } from "@tikifu/shared/types";
import { CanvasState } from "../types";

export abstract class Element {
  id = Symbol();
  dbId?: number;
  serverId: number | undefined = undefined;
  abstract properties: TextProperties | ImageProperties;
  abstract state: CanvasState;

  abstract draw(ctx: CanvasRenderingContext2D): void;

  abstract containsPoint(x: number, y: number, padding?: number): boolean;
}
