import { useEffect, useState } from "react";
import { Crown } from "@phosphor-icons/react";
import { createPaymentLink } from "../services/payments";
import Button from "../components/Button";
import { TIKTOK_AUTH_URL } from "../util/config";
import Modal from "../components/Modal";
import { useAppStore } from "../hooks/useAppStore";

const UpgradeModalContent = () => {
  const [paymentLink, setPaymentLink] = useState("");

  useEffect(() => {
    const initialize = async () => {
      const { url } = await createPaymentLink();
      setPaymentLink(url);
    };

    void initialize();
  }, []);

  return (
    <>
      <h2 className="text-xl font-semibold text-center">Get Tikifu Pro</h2>
      <div className="flex flex-row gap-2 justify-center">
        <Crown className="text-orange-400" size={24} />
        Unlock all Tikifu features for $15 a month.
        <Crown className="text-orange-400" size={24} />
      </div>
      <Button
        color="black"
        onClick={() => {
          window.location.href = paymentLink;
        }}
      >
        Get Pro
      </Button>
    </>
  );
};

const AddTiktokAccountModalContent = () => {
  return (
    <>
      <h2 className="text-xl font-semibold text-center">
        Add a New TikTok Account
      </h2>
      <p>
        Please logout or login to the account you&apos;d like to add on TikTok
        before clicking the button below.
      </p>
      <Button
        color="black"
        onClick={() => {
          window.location.href = TIKTOK_AUTH_URL;
        }}
      >
        Add Account
      </Button>
    </>
  );
};

const MODAL_TYPES = ["tiktok", "upgrade"];

const Modals = () => {
  const { modal, setModal } = useAppStore();

  return (
    <Modal
      open={modal ? MODAL_TYPES.includes(modal) : false}
      onClose={() => {
        setModal(null);
      }}
    >
      {modal === "tiktok" ? (
        <AddTiktokAccountModalContent />
      ) : modal === "upgrade" ? (
        <UpgradeModalContent />
      ) : null}
    </Modal>
  );
};

export default Modals;
