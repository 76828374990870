import { CanvasRefState } from "../types";
import { isImageElement } from "../util/guards";

const useDraw = () => {
  const draw = (state: CanvasRefState) => {
    const canvas = state.ref.current;

    if (!canvas) return;

    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    const bgImg = state.elements.find(isImageElement);

    if (bgImg) {
      ctx.canvas.width = bgImg.state.img.naturalWidth;
      ctx.canvas.height = (16 / 9) * bgImg.state.img.naturalWidth;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    state.elements.forEach((element) => {
      element.draw(ctx);

      if (element.state.selected) {
        ctx.strokeStyle = "#39FF14";
        ctx.lineWidth = 0.007 * canvas.width;
        ctx.strokeRect(
          element.properties.x - element.state.width / 2,
          element.properties.y - element.state.height / 2,
          element.state.width,
          element.state.height,
        );
      }
    });

    if (state.selected) {
      ctx.strokeStyle = "#39FF14";
      ctx.lineWidth = 0.007 * canvas.width;
      ctx.strokeRect(0, 0, canvas.width, canvas.height);
    }

    const canvasWidth = canvas.width;
    const middleX = canvasWidth / 2;

    const isCentered = state.elements.some((element) => {
      const objCenterX = element.properties.x;
      return (
        Math.abs(objCenterX - middleX) < 0.007 * canvas.width &&
        element.state.dragging
      );
    });

    if (isCentered) {
      ctx.strokeStyle = "#39FF14";
      ctx.lineWidth = 0.007 * canvas.width;
      const middleX = canvas.width / 2;
      ctx.beginPath();
      ctx.moveTo(middleX, 0);
      ctx.lineTo(middleX, canvas.height);
      ctx.stroke();
      ctx.setLineDash([]);
    }
  };

  return draw;
};

export default useDraw;
