import { Login, Provider, Token } from "@tikifu/shared/types";
import { API_URL } from "../util/config";
import { post } from "../util/req";

const tiktokLogin = (body: Login) =>
  post<Token>(`${API_URL}/auth/tiktok/login`, body);

const googleLogin = (body?: Login) =>
  post<Token>(`${API_URL}/auth/google/login`, body);

const verifyGoogleLogin = () =>
  post<{ userId: number }>(`${API_URL}/auth/google/verify`);

const verifyTiktokLogin = (body: Provider) =>
  post<{}>(`${API_URL}/auth/tiktok/verify`, body);

export { tiktokLogin, googleLogin, verifyGoogleLogin, verifyTiktokLogin };
