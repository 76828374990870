import { ReactNode } from "react";
import { createPortal } from "react-dom";

interface ModalProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
}

const Modal = ({ children, open, onClose }: ModalProps) => {
  const root = document.getElementById("root");

  if (!open || !root) return null;

  return createPortal(
    <div className="fixed inset-0 z-50">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={onClose}
        role="presentation"
      ></div>
      <div className="flex flex-col gap-5 relative z-50 bg-white p-5 rounded-lg w-full max-w-md shadow-lg top-1/2 -translate-y-1/2 m-auto">
        <button
          className="absolute top-2 right-4 text-2xl font-bold cursor-pointer focus:outline-none"
          onClick={onClose}
        >
          &times;
        </button>
        {children}
      </div>
    </div>,
    root,
  );
};

export default Modal;
