import React, {
  forwardRef,
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Property } from "csstype";
import useDraw from "../../hooks/useDraw";
import { isImageElement, isTextElement } from "../../util/guards";
import { debounce } from "../../util/helpers";
import { updateElement } from "../../services/elements";
import { CanvasRefState } from "../../types";

interface CanvasProps
  extends Omit<
    React.HTMLAttributes<HTMLDivElement>,
    "width" | "height" | "ref" | "onMouseMove" | "onMouseUp"
  > {
  canvasRef: MutableRefObject<CanvasRefState[]>;
  lastClickedElement: symbol | null;
  setLastClickedElement: React.Dispatch<React.SetStateAction<symbol | null>>;
  index: number;
}

const Canvas = forwardRef<HTMLCanvasElement | null, CanvasProps>(
  (
    {
      canvasRef,
      lastClickedElement,
      setLastClickedElement,
      index,
      ...props
    }: CanvasProps,
    ref,
  ) => {
    const [cursor, setCursor] = useState<Property.Cursor>("");
    const actionRef = useRef<"left" | "right" | "top" | "bottom" | "move" | "">(
      "",
    );
    const draw = useDraw();

    const debouncedUpdateElement = useCallback(
      debounce(
        (...args: Parameters<typeof updateElement>) => updateElement(...args),
        500,
      ),
      [],
    );

    useLayoutEffect(() => {
      draw(canvasRef.current[index]);
    }, [canvasRef.current.length]);

    const bgImg = canvasRef.current[index].elements.find(isImageElement);

    const scale = bgImg
      ? bgImg.state.img.naturalWidth /
        (canvasRef.current[index].ref.current?.getBoundingClientRect().width ??
          1)
      : 1;

    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = canvasRef.current[index].ref.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      const rect = canvas.getBoundingClientRect();
      const clickX = (e.clientX - rect.left) * scale;
      const clickY = (e.clientY - rect.top) * scale;

      const clickedElement = canvasRef.current[index].elements.find(
        (element) =>
          element.containsPoint(clickX, clickY, 0.03 * canvas.width) &&
          element.properties.active,
      );

      const selectMultiple = e.ctrlKey || e.shiftKey || e.metaKey;

      if (!clickedElement) {
        setLastClickedElement(null);
      }

      if (!selectMultiple || !clickedElement) {
        for (const data of canvasRef.current) {
          for (const element of data.elements) {
            element.state.selected = false;
          }
        }
      }

      if (clickedElement) {
        clickedElement.state.selected = true;
        for (const data of canvasRef.current) {
          data.selected = false;
        }
      } else {
        if (!selectMultiple) {
          for (const state of canvasRef.current) {
            state.selected = false;
          }
        }
        canvasRef.current[index].selected = true;
      }

      for (const state of canvasRef.current) {
        draw(state);
      }
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = canvasRef.current[index].ref.current;
      if (!canvas) return;

      const rect = canvas.getBoundingClientRect();
      const mouseX = (e.clientX - rect.left) * scale;
      const mouseY = (e.clientY - rect.top) * scale;

      const threshold = canvas.width * 0.03;

      const hoveredElement = canvasRef.current[index].elements.find(
        (element) =>
          element.containsPoint(mouseX, mouseY, threshold) &&
          element.properties.active,
      );

      if (hoveredElement && e.buttons !== 1) {
        const leftEdge =
          hoveredElement.properties.x - hoveredElement.state.width / 2;
        const rightEdge =
          hoveredElement.properties.x + hoveredElement.state.width / 2;
        const topEdge =
          hoveredElement.properties.y - hoveredElement.state.height / 2;
        const bottomEdge =
          hoveredElement.properties.y + hoveredElement.state.height / 2;
        if (Math.abs(mouseX - leftEdge) <= threshold) {
          actionRef.current = "left";
        } else if (Math.abs(mouseX - rightEdge) <= threshold) {
          actionRef.current = "right";
        } else if (Math.abs(mouseY - topEdge) <= threshold) {
          actionRef.current = "top";
        } else if (Math.abs(mouseY - bottomEdge) <= threshold) {
          actionRef.current = "bottom";
        } else {
          actionRef.current = "move";
        }
      }

      setCursor(
        hoveredElement?.state.selected
          ? actionRef.current === "left" || actionRef.current === "right"
            ? "ew-resize"
            : actionRef.current === "top" || actionRef.current === "bottom"
              ? "ns-resize"
              : "move"
          : hoveredElement
            ? "pointer"
            : canvasRef.current[index].selected
              ? "move"
              : "pointer",
      );

      if (e.buttons !== 1) return;

      const canvasWidth = canvas.width;
      const middleX = canvasWidth / 2;

      for (const data of canvasRef.current) {
        for (const element of data.elements) {
          const leftEdge = element.properties.x - element.state.width / 2;
          const rightEdge = element.properties.x + element.state.width / 2;
          const topEdge = element.properties.y - element.state.height / 2;
          const bottomEdge = element.properties.y + element.state.height / 2;
          const isNearCenter = Math.abs(element.state.x - middleX) < 20;
          if (
            element.state.selected &&
            (!isTextElement(element) || !element.state.editable)
          ) {
            if (actionRef.current === "left") {
              const newWidth = rightEdge - mouseX;
              if (newWidth > 5) {
                element.state.width = newWidth;
                element.state.x = mouseX + newWidth / 2;
                element.properties.x = element.state.x;
              }
            } else if (actionRef.current === "right") {
              const newWidth = mouseX - leftEdge;
              if (newWidth > 5) {
                element.state.width = newWidth;
                element.state.x = leftEdge + newWidth / 2;
                element.properties.x = element.state.x;
              }
            } else if (actionRef.current === "top") {
              const newHeight = bottomEdge - mouseY;
              if (newHeight > 5) {
                element.state.height = newHeight;
                element.state.y = mouseY + newHeight / 2;
                element.properties.y = element.state.y;
              }
            } else if (actionRef.current === "bottom") {
              const newHeight = mouseY - topEdge;
              if (newHeight > 5) {
                element.state.height = newHeight;
                element.state.y = topEdge + newHeight / 2;
                element.properties.y = element.state.y;
              }
            } else {
              element.state.x = mouseX;
              element.state.y = mouseY;
              element.properties.y = element.state.y;
              if (!isNearCenter) {
                element.properties.x = element.state.x;
              } else {
                element.properties.x = middleX;
              }
            }
            if (element.dbId) {
              debouncedUpdateElement(element.dbId, {
                properties: {
                  x: element.properties.x,
                  y: element.properties.y,
                },
              });
            }
            element.state.dragging = true;
          }
        }
        draw(data);
      }
    };

    const handleMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = canvasRef.current[index].ref.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      const rect = canvas.getBoundingClientRect();
      const clickX = (e.clientX - rect.left) * scale;
      const clickY = (e.clientY - rect.top) * scale;

      const clickedElement = canvasRef.current[index].elements.find(
        (element) =>
          element.containsPoint(clickX, clickY, 0.03 * canvas.width) &&
          element.properties.active,
      );

      if (!clickedElement) return;

      if (
        clickedElement.id === lastClickedElement &&
        isTextElement(clickedElement) &&
        !clickedElement.state.dragging
      ) {
        clickedElement.state.editable = true;
      }

      actionRef.current = "";

      for (const data of canvasRef.current) {
        for (const element of data.elements) {
          element.state.dragging = false;
        }
        draw(data);
      }

      setLastClickedElement(clickedElement.id);
    };

    const textElements = canvasRef.current[index].elements
      .filter(isTextElement)
      .filter((element) => element.state.editable);

    return (
      <div className="flex relative" {...props}>
        <canvas
          className={`w-[300px] h-[${(16 / 9) * 300}px]`}
          style={{ cursor }}
          ref={ref}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        />
        {textElements.map((textElement, i) => (
          <textarea
            key={i}
            wrap="off"
            rows={1}
            defaultValue={textElement.properties.text}
            onChange={(e) => {
              textElement.properties.text = e.target.value;
              e.currentTarget.style.height = "auto";
              e.currentTarget.style.width = "auto";
              e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
              e.currentTarget.style.width = `${e.currentTarget.scrollWidth}px`;
              draw(canvasRef.current[index]);
            }}
            style={{
              left: textElement.properties.x * (1 / scale),
              top: textElement.properties.y * (1 / scale),
              fontFamily: textElement.properties.fontFamily,
              fontSize: textElement.properties.fontSize * (1 / scale),
              lineHeight: 1.2,
            }}
            className={`leading-[1.2] align-middle flex items-center justify-center absolute transform -translate-x-1/2 -translate-y-1/2 text-center whitespace-pre outline-none overflow-hidden resize-none p-0 border-none text-transparent bg-transparent caret-black`}
            onFocus={(e) => {
              e.target.setSelectionRange(0, e.target.value.length);
              e.currentTarget.style.height = "auto";
              e.currentTarget.style.width = "auto";
              e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
              e.currentTarget.style.width = `${e.currentTarget.scrollWidth}px`;
            }}
            onBlur={() => {
              textElement.state.editable = false;
            }}
            autoFocus
          />
        ))}
      </div>
    );
  },
);

Canvas.displayName = "Canvas";

export default Canvas;
