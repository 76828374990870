import { Link, useLocation, useNavigate } from "react-router-dom";
import { Plus, File } from "@phosphor-icons/react";
import logoSrc from "../logo_white.svg";
import { createProject } from "../services/projects";
import { useAppStore } from "../hooks/useAppStore";
import Button from "../components/Button";
import Dropdown from "../components/Dropdown";

const Sidebar = () => {
  const { user, setSelectedUser, setModal } = useAppStore();
  const navigate = useNavigate();

  const handleCreateNewProject = async () => {
    const project = await createProject({ name: "new project" });
    navigate(`/projects/${project.id}`);
  };

  const location = useLocation();

  const handleUserSelect = (option: string) => {
    if (!user) {
      throw new Error("User missing");
    }

    switch (option) {
      case "Add TikTok account": {
        setModal("tiktok");
        break;
      }
      default: {
        const foundUser = user.children.find((user) => user.name === option);
        if (!foundUser) {
          throw new Error("Selected user not found");
        }
        setSelectedUser(foundUser.id);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col justify-between bg-[#222222] text-white py-8 shadow-md px-3">
        <div className="flex flex-col">
          <Link className="self-center" to="/">
            <img
              className="w-20 h-auto mb-6 self-center"
              src={logoSrc}
              alt="Logo"
            />
          </Link>
          <Button
            className="flex flex-row items-center gap-4 mb-4 py-2 px-4"
            onClick={() => void handleCreateNewProject()}
          >
            <Plus className="5-4 h-5" weight="bold" />
            New Project
          </Button>
          <Link
            to="/projects"
            className={`flex flex-row gap-2 items-center rounded-xl w-full text-center px-2 py-1.5 transition-opacity hover:bg-indigo-700`}
          >
            <File
              className="w-5 h-5"
              weight={
                location.pathname.includes("/projects") ? "fill" : "regular"
              }
            />
            Projects
          </Link>
        </div>
        {user && (
          <Dropdown
            direction="up"
            className="w-32 self-center"
            options={[
              "Add TikTok account",
              ...user.children.map((user) => user.name),
            ]}
            onSelect={handleUserSelect}
            defaultValue={
              user.children.length
                ? user.children[0].name
                : "Add TikTok account"
            }
          />
        )}
      </div>
    </>
  );
};

export default Sidebar;
