import { ProjectPage, User } from "@tikifu/shared/types";
import { create } from "zustand";
import { Modal } from "../types";

interface AppState {
  user: User | null;
  selectedUser: number | null;
  loading: boolean;
  projectPage: ProjectPage | null;
  canvasRender: number;
  modal: Modal;
  setUser: (user: User | null | ((prev: User | null) => User | null)) => void;
  setSelectedUser: (
    user: number | null | ((prev: number | null) => number | null),
  ) => void;
  setLoading: (loading: boolean | ((prev: boolean) => boolean)) => void;
  setProjectPage: (
    projectPage:
      | ProjectPage
      | null
      | ((prev: null | ProjectPage) => ProjectPage | null),
  ) => void;
  setCanvasRender: (count: number | ((prev: number) => number)) => void;
  setModal: (modal: Modal | ((prev: Modal) => Modal)) => void;
}

export const useAppStore = create<AppState>((set) => ({
  user: null,
  selectedUser: null,
  loading: true,
  projectPage: null,
  canvasRender: 0,
  modal: null,

  setUser: (user) => {
    set((state) => ({
      user: typeof user === "function" ? user(state.user) : user,
    }));
  },

  setLoading: (loading) => {
    set((state) => ({
      loading: typeof loading === "function" ? loading(state.loading) : loading,
    }));
  },

  setProjectPage: (projectPage) => {
    set((state) => ({
      projectPage:
        typeof projectPage === "function"
          ? projectPage(state.projectPage)
          : projectPage,
    }));
  },

  setSelectedUser: (selectedUser) => {
    set((state) => ({
      selectedUser:
        typeof selectedUser === "function"
          ? selectedUser(state.selectedUser)
          : selectedUser,
    }));
  },

  setCanvasRender: (count) => {
    set((state) => ({
      canvasRender:
        typeof count === "function" ? count(state.canvasRender) : count,
    }));
  },

  setModal: (modal) => {
    set((state) => ({
      modal: typeof modal === "function" ? modal(state.modal) : modal,
    }));
  },
}));
