import { PoolCreate, Pool } from "@tikifu/shared/types";
import { API_URL } from "../util/config";
import { post } from "../util/req";

const scheduleTiktokPool = (id: number, body: PoolCreate) =>
  post<Pool>(`${API_URL}/schedule/tiktok/pool`, body, {
    "x-tiktok-user-id": id.toString(),
  });

export { scheduleTiktokPool };
